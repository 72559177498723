<template>
 <div class="content">
   <app-cells position="between">
     <app-cells :indent="false">
       <h1 class="title title--theme">СМИ</h1>
<!--       <router-link v-if="userIsAdmin" :to="{ name: 'smi-stat' }" class="btn btn&#45;&#45;link">-->
<!--         <img src="@/assets/img/sidebar/stat.svg" alt="Stat icon">-->
<!--         <span>Статистика</span>-->
<!--       </router-link>-->
     </app-cells>
     <v-select
       v-if="userIsAdmin"
       :reduce="item => item.id"
       :options="delegation_options"
       :filterable="true"
       :clearable="false"
       v-model="user_delegation_id"
       @input="onSetDelegation"
       label="name"
       placeholder="Выберите делегацию"
       class="select select--minw"
     >
       <template #open-indicator>
         <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
           <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
           <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
         </svg>
       </template>
       <template #option="option">
         <div class="select__item d-center">{{ option.name }}</div>
       </template>
       <template #selected-option="option">
         <div class="selected d-center">{{ option.name }}</div>
       </template>
       <template #no-options>Ничего не найдено</template>
     </v-select>
   </app-cells>
   <app-cells position="start">
     <router-link :to="{ name: 'smi-create' }" class="btn btn--link btn--link-icon">
       <span>Добавить СМИ</span>
     </router-link>
   </app-cells>
   <app-table-head
     @update-limiter="onUpdateLimiter"
     :count="table.items.length"
     :page="pagination.page"
     :all="pagination.count"
   />
   <v-client-table
     @row-click="onRowClick"
     :data="table.items"
     :columns="table.columns"
     :options="table.options"
     ref="tableDefault"
     class="table-default table-default--dynamic"
   >
     <div slot="last_name" slot-scope="props" class="table-default__left">
       {{ props.row.last_name }} {{ props.row.first_name }} {{ props.row.patronymic ? props.row.patronymic : '' }}
     </div>
     <template #birthday="props">
       <span>{{ formatDate(props.row.birthday) }}</span>
       <br>
       <span
         class="red"
         :class="{
            'green': getAge(props.row.age) >= 18 && getAge(props.row.age) <= 35,
            'yellow': getAge(props.row.age) > 35 || (getAge(props.row.age) < 18 && getAge(props.row.age) >= 16)
          }"
       >
          {{ props.row.age }}
        </span>
     </template>
   </v-client-table>
   <paginate
     v-if="pagination.pages > 1"
     v-model="pagination.page"
     :page-count="pagination.pages"
     :clickHandler="onClickPagination"
     :prev-text="'<'"
     :next-text="'>'"
     :container-class="'pagination'"
     :page-class="'pagination__item'"
     :page-link-class="'pagination__link'"
     :prev-class="'pagination__prev'"
     :next-class="'pagination__next'"
     :next-link-class="'pagination__link-next'"
     :prev-link-class="'pagination__link-prev'"
   />
 </div>
</template>

<script>
import { getDelegations, getSmiList, patchUser } from '@/http'
import { dateFormatting } from '@/helpers'

export default {
  name: 'SmiList',
  data() {
    return {
      table: {
        items: [],
        columns: ['pk', 'last_name', 'gender_obj.name', 'birthday', 'status_obj.name'],
        options: {
          headings: {
            'pk': 'ID',
            'last_name': 'ФИО',
            'gender_obj.name': 'Пол',
            'birthday': 'Дата рождения',
            'status_obj.name': 'Статус',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Вы еще не добавили ни одного СМИ',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status_obj.code}`,
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
      },
      table_limit: 20,
      delegation_options: [],
      user_delegation_id: null,
    }
  },
  computed: {
    userIsAdmin() {
      return this.$store.state.user_role === 'admin'
    },
  },
  created() {
    this.fetchItems()
    setTimeout(() => {
      if (this.userIsAdmin) {
        getDelegations()
          .then(response => {
            this.delegation_options = response.data
            this.user_delegation_id = this.$store.state.delegation
            if (this.$route.params.delegation_id) {
              this.user_delegation_id = this.$route.params.delegation_id
              this.onSetDelegation()
            }
          })
      }
    }, 200)
  },
  methods: {
    fetchItems() {
      getSmiList(this.pagination.page, this.table_limit)
        .then(response => {
          this.table.items = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
    },
    formatDate(date) {
      return dateFormatting(date, 'iso-to-normal-small')
    },
    getAge(ageStr) {
      return ageStr.split(' ')[0]
    },
    onRowClick(row) {
      this.$router.push({ name: 'smi-detail', params: { id: row.row.pk } })
    },
    onUpdateLimiter(data) {
      this.table_limit = data
      this.pagination.page = 1
      this.$refs.tableDefault.setLimit(this.table_limit)
      this.fetchItems()
    },
    onClickPagination(page) {
      this.pagination.page = page
      this.fetchItems()
    },
    onSetDelegation() {
      this.$store.commit('setDelegation', this.user_delegation_id)
      patchUser({ delegation: this.user_delegation_id })
        .then(response => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Делегация успешно изменена'
          })
          this.fetchItemsLegal()
          this.fetchItemsIndividual()
          this.$store.commit('setDelegationHash', response.data.delegation_hash)
        })
    },
  }
}
</script>

<style scoped>

</style>
